.container {
  margin: var(--icon-size-large) var(--space-xl) var(--space-xl);
}

.headline {
  white-space: nowrap;
}

.spinnerContainer {
  background-color: var(--color-grey-10);
  padding: var(--space-s);
  text-align: center;
  height: calc(2em + 2 * var(--space-s));
}

.vehicleCode {
  background-color: var(--color-grey-10);
  padding: var(--space-s);
  text-align: center;
  font-size: 2em;
  line-height: 1;
  margin: 0;
}
