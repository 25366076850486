.pageContainer {
  margin-right: auto;
  margin-left: auto;
}

.pageContent {
  max-width: calc(1400px + var(--sidebar-closed-width));
  padding-left: var(--sidebar-closed-width);
  flex-grow: 1;
  background-color: var(--color-white);
}

@media screen and (min-width: 1281px) {
  .pageContainer {
    display: flex;
    justify-content: center;
  }

  .pageContent {
    padding-left: 0;
  }
}
