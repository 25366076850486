.comparePageWrapper {
  position: relative;
}

.compareTypeWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
}

.compareTypeWrapper::before {
  width: 100%;
  height: 1px;
  background-color: var(--color-grey-50);
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  content: "";
}

.compareType {
  padding: var(--space-s) var(--space-m);
  position: relative;
  border: 1px solid var(--color-grey-50);
  margin-left: -1px;
  cursor: pointer;
  background-color: var(--color-grey-05);
}

.compareTypeActive {
  border-bottom-color: #fff;
  background-color: #fff;
}

.spinnerContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #fff;
  opacity: 50%;
}

.spinnerContainer svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  margin: -24px 0 0 -24px;
}
