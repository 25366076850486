.optionLabel {
  display: flex;
  align-items: center;
  font-size: 1em;
  padding: 10px;
}

.optionLabel input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.dropdown__option {
  padding: 12px;
  font-size: 1em;
}

.selectedCounter {
  display: inline-block;
  width: inherit;
  height: inherit;
  background-color: var(--color-grey-10);
  color: var(--color-black);
  text-align: center;
  line-height: inherit;
  margin-left: 5px;
}

.placeHolder {
  display: flex;
  align-items: center;
}
