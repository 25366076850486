.container {
  position: relative;
  background-color: var(--color-white);
}

.button {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: var(--space-s);
  border-width: 0;
  background-color: transparent;
}

.img,
.placeholderImg {
  display: block;
}

.thumbnailList {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: var(--space-s);
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.thumbnail {
  width: 20px;
  height: 8px;
  margin-right: var(--space-xs);
  margin-left: var(--space-xs);
  background-color: var(--color-grey-80);
  cursor: pointer;
  box-shadow: 0 0 2px 2px var(--color-white);
}
