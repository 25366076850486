.selectorContainer {
  display: flex;
  gap: 10px;
}

.react-select__control {
  border-radius: 4px;
  border: 1px solid #ddd;
}

.react-select__placeholder {
  color: gray;
}

.react-select__multi-value {
  background: #d9d9d9;
  border-radius: 16px;
  color: black;
  padding: 4px 8px;
}

.menuOption {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.slotNum {
  background-color: var(--color-grey-20);
  padding: 3px 6px;
  font-weight: var(--font-weight-bold);
  align-self: flex-start;
}

.checkbox {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  border: 1px solid var(--color-grey-60);
  cursor: pointer;
  appearance: none;
  flex-shrink: 0;
}

.compareButton {
  padding: var(--space-xs) var(--space-l);
  background-color: var(--primary-button-bg-color);
  color: var(--primary-button-color);
  border: none;
  cursor: pointer;
  transition: background-color .3s;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: var(--space-xs);
}

.compareButton[disabled] {
  color: var(--color-grey-20);
  background: var(--color-grey-60);
}
