.modalContainer {
  opacity: 0;
  animation-name: fade-in;
  animation-fill-mode: forwards;
  animation-duration: .4s;
  animation-delay: .4s;
}

.modalContent {
  padding: var(--space-m);
  background-color: transparent;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
