.container {
  margin-left: auto;
  width: 250px;
}

.label {
  display: block;
  font-size: .875em;
  color: var(--label-font-color);
}
