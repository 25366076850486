.container {
  position: fixed;
  top: calc(var(--space-m) - var(--space-xxs));
  right: var(--space-m);
  z-index: 5;
}

.list {
  width: 18.5rem;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.listItem {
  margin-top: var(--space-xxs);
}

.wrapper {
  display: flex;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .1), 0 0 40px 0 rgba(0, 0, 0, .15);
}

.wrapper-error {
  background-color: var(--color-red);
  color: var(--color-white);
}

.wrapper-info {
  background-color: var(--color-green);
  color: var(--color-white);
}

.message {
  flex-grow: 1;
  align-self: center;
  padding: .75rem;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.button {
  align-self: flex-start;
  border-width: 0;
  padding: 0;
  background-color: transparent;
}

.icon {
  display: block;
  color: var(--color-white);
}
