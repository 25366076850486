.table {
  width: 100%;
  border-collapse: collapse;
}

.td {
  border-width: 1px 0 0;
  border-style: solid;
  border-color: var(--color-grey-50);
  vertical-align: top;
}

.tr:last-child td {
  border-bottom-width: 1px;
}
