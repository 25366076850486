.container {
  padding-right: var(--space-column);
  padding-bottom: var(--space-xxl);
  padding-left: var(--space-column);
}

.headerContainer {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xl);
}
