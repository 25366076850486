.header {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xl);
}

.title {
  font-size: 1.125em;
}

.disclaimer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: var(--space-s);
  align-items: center;
}
