@font-face {
  font-family: SeatRegular;
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/seat-regular.woff") format("woff");
}

@font-face {
  font-family: SeatRegular;
  font-style: normal;
  font-weight: bold;
  src: url("../../../assets/fonts/seat-bold.woff") format("woff");
}

@font-face {
  font-family: SeatMedium;
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/seat-medium.woff") format("woff");
}

:root {
  --seat-regular-type-text: "SeatRegular", sans-serif;
  --seat-medium-type-text: "SeatRegular", sans-serif;
}
