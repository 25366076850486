.container {
  list-style: none;
  max-width: 400px;
  text-align: left;
  padding-left: var(--space-m);
}

.blockEntry {
  display: block;
  margin: 0;
}

.entryKey,
.entryValue {
  padding: var(--space-xs);
  flex-grow: 1;
}

.entryValue {
  white-space: normal;
  line-height: 1.4;
  padding: 0;
}
