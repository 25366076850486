/* stylelint-disable color-no-hex */
@import "fonts.css";

:global(body.cupra) {
  --color-black: #000;
  --color-white: #fff;
  --color-green: #4c6856;
  --color-red: #af1e23;
  --color-yellow: #fcc446;
  --color-orange: #ea5d1a;
  --color-grey-05: #f2f2f2;
  --color-grey-10: #e5e5e5;
  --color-grey-15: #d9d9d9;
  --color-grey-20: #ccc;
  --color-grey-30: #b3b3b3;
  --color-grey-40: #999;
  --color-grey-50: #808080;
  --color-grey-60: #666;
  --color-grey-70: #4c4c4c;
  --color-grey-80: #333;
  --color-grey-90: #1a1a1a;

  /* font-face font-weight */
  --font-weight-bold: 700;
  --font-weight-light: 200;
  --font-weight-normal: 400;

  /* size variables */
  --space-xxs: 3px;
  --space-xs: 6px;
  --space-s: 12px;
  --space-m: 18px;
  --space-l: 24px;
  --space-xl: 30px;
  --space-xxl: 36px;
  --space-xxxl: 42px;
  --space-column: 4%;

  /* icon variables */
  --icon-size-small: 24px;
  --icon-size-large: 48px;

  /* timing variables */
  --time-xxs: 30ms;
  --time-xs: 60ms;
  --time-s: 120ms;
  --time-m: 180ms;
  --time-l: 240ms;
  --time-xl: 420ms;
  --time-xxl: 660ms;
  --time-xxxl: 960ms;
  --transition-ease-in: cubic-bezier(.69, .01, .83, .56);
  --transition-ease-out: cubic-bezier(.21, .55, .43, 1);
  --transition-ease-in-out: cubic-bezier(.75, .02, .5, 1);

  /* usage: transition-timing-function: var(--transition-ease-in); */

  /* font */
  --primary-font-family: var(--seat-regular-type-text);
  --secondary-font-family: var(--seat-medium-type-text);
  --primary-font-color: var(--color-grey-80);
  --secondary-font-color: var(--color-grey-50);

  /* color */
  --warning-color: var(--color-red);
  --success-color: var(--color-green);
  --incomplete-color: var(--color-yellow);

  /* link */
  --link-color: var(--primary-font-color);

  /* button */
  --primary-button-color: var(--color-white);
  --primary-button-bg-color: var(--color-orange);

  /* form */
  --label-font-color: var(--color-grey-50);
  --input-border-color: var(--color-grey-80);
  --input-border-focus-color: var(--color-grey-80);

  /* table */
  --table-border-color: var(--color-grey-50);

  /* sidebar */
  --sidebar-bg-color: var(--color-grey-05);
  --sidebar-link-color: var(--color-grey-70);
  --sidebar-link-hover-color: var(--color-black);
  --sidebar-link-disabled-color: var(--color-grey-50);
  --sidebar-link-active-color: var(--color-black);
  --sidebar-link-active-bg-color: var(--color-grey-20);
  --sidebar-traffic-light-done-color: var(--success-color);
  --sidebar-traffic-light-error-color: var(--color-red);
  --sidebar-traffic-light-incomplete-color: var(--incomplete-color);
  --sidebar-traffic-light-notstarted-color: var(--color-grey-20);

  /* loading indicator */
  --isLoadingBgColor: var(--color-white);
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  :global(body.cupra) {
    --space-xxxl: 48px;
  }
}

@media all and (min-width: 1024px) and (max-width: 1439px) {
  :global(body.cupra) {
    --space-xxl: 42px;
    --space-xxxl: 60px;
  }
}

@media all and (min-width: 1440px) and (max-width: 1919px) {
  :global(body.cupra) {
    --space-xl: 36px;
    --space-xxl: 54px;
    --space-xxxl: 78px;
  }
}

@media all and (min-width: 1920px) {
  :global(body.cupra) {
    --space-xl: 42px;
    --space-xxl: 66px;
    --space-xxxl: 96px;
  }
}
/* stylelint-enable */
