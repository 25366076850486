/* Increase specificity with parent selector to avoid conflicts with React-Select inline styles */
.dropdown .dropdown__control {
  border: none;
  border-bottom: 1px solid var(--input-border-color);
  border-radius: 0;
  min-width: 170px;
}

.dropdown .dropdown__control--is-focused {
  border: none;
  border-bottom: 1px solid var(--input-border-color);
  box-shadow: 0 1px var(--input-border-focus-color);
}

.dropdown .dropdown__control:hover {
  border-bottom: 1px solid var(--input-border-color);
}

.dropdown .dropdown__value-container {
  padding: 0;
}

.dropdown .dropdown__menu {
  margin-top: 0;
  border-radius: 0;
}

.dropdown .dropdown__menu-list {
  padding: 0;
}

.dropdown .dropdown__option {
  word-wrap: break-word;
  overflow: hidden;
}

.dropdown .dropdown__placeholder {
  color: var(--color-black);
}

.dropdown .dropdown__single-value {
  color: var(--color-black);
}
