.vehicleItem {
  border: 1px solid var(--color-grey-15);
  padding: var(--space-m);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .1);
}

.header {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.headerSlot {
  background-color: var(--color-grey-20);
  padding: 5px 10px;
  font-weight: var(--font-weight-bold);
}

.label {
  font-weight: var(--font-weight-bold);
}

.notice {
  background-color: var(--color-grey-05);
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--primary-font-color);
}

.vehicleDetailsWrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  border-top: 1px solid var(--color-grey-20);
  border-bottom: 1px solid var(--color-grey-20);
  padding: 20px 0;
}

.vehicleDetails {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-auto-rows: min-content;
  gap: 20px 20px;
}

.vehicleAdditionalDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 30%;
}

.vehiclePrice {
  display: flex;
  margin-left: auto;
  gap: 10px;
}

.listOfDetails {
  list-style-position: outside;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1.5em;
}

.inline {
  display: flex;
  gap: 10px;
}

.inline p {
  margin: 0;
}

.start {
  align-self: flex-start;
}

.footerUtilities {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-top: var(--space-m);
}

.footerButtons {
  display: flex;
  gap: 5px;
}

.footerButtons button {
  flex: 0 1 auto;
  padding: var(--space-xs) var(--space-l);
  background-color: var(--primary-button-bg-color);
  color: var(--primary-button-color);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: var(--space-xs);
}

/* Modal */
.modalContent {
  padding: var(--space-xl);
}

.modalContentTitle {
  margin-top: .5em;
  text-align: center;
}

.modalButton {
  padding: var(--space-xs) var(--space-l);
  background-color: var(--primary-button-bg-color);
  color: var(--primary-button-color);
  border: none;
  cursor: pointer;
  transition: background-color .3s;
}

.modalTitle {
  text-align: center;
}

.modalButtons {
  display: flex;
  gap: 10px;
  justify-content: end;
  padding-top: 20px;
}

.deleteModalTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.modalTextArea {
  width: 100%;
  min-width: 500px;
  height: 150px;
  border-color: var(--input-border-color);
}

.modalTextArea:focus {
  border-color: var(--input-border-color);
  outline: none;
}

.modalSlotNum {
  background-color: var(--color-grey-20);
  padding: 5px 10px;
  font-weight: var(--font-weight-bold);
}

.modalModelName {
  font-weight: var(--font-weight-bold);
}

.modalReferenceDetails {
  padding-bottom: var(--space-s);
  display: flex;
  gap: 5px;
  align-items: center;
}

/* Placeholder */
.footerButtonsPlaceholders {
  justify-content: flex-end;
  width: 100%;
}

.vehicleDetailsPlaceholder {
  width: 30%;
  margin-left: auto;
}

.headerSlotPlaceholder {
  margin-right: 10px;
}

.compareButtonPlaceholder {
  padding: var(--space-xs) var(--space-xl);
  background-color: var(--primary-button-bg-color);
  color: var(--primary-button-color);
  border: none;
  cursor: pointer;
  transition: background-color .3s;
  display: flex;
  align-items: center;
}

.comparisonSelectorPlaceholder {
  display: flex;
  gap: 10px;
}

.comparisonSelectorBoxPlaceholder {
  width: 200px;
  border: 1px solid var(--color-grey-20);
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.noticePlaceholder {
  height: 30px;
}

@media screen and (max-width: 1000px) {
  .footerUtilities {
    flex-wrap: wrap;
    justify-content: center;
  }

  .vehiclePrice {
    flex-direction: column;
    align-items: center;
  }

  .vehicleAdditionalDetails {
    margin-left: auto;
  }
}
