.select,
.textInput {
  appearance: none;
  height: 40px;
  font: inherit;
  padding: 0;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom: 1px solid var(--input-border-color);
  border-left-width: 0;
  transition: box-shadow var(--transition-ease-in-out) .25s;
}

.select {
  max-width: 100%;
  padding-right: var(--space-l);
  background-color: transparent;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik03IDEwTDEyLjUgMTUuNSAxOCAxMCIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+Cgo=");
  background-repeat: no-repeat;
  background-position: right center;
  border-radius: 0;
  text-overflow: ellipsis;
  cursor: pointer;
}

.select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 black;
}

.select:focus,
.textInput:focus {
  box-shadow: 0 1px var(--input-border-focus-color);
}

.checkbox,
.radio {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  border: 1px solid var(--color-grey-60);
  cursor: pointer;
  appearance: none;
  flex-shrink: 0;
}

.checkbox::before,
.radio::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik02IDEyLjJMMTAuMyAxNi40IDE4LjQgNi4xIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4KCg==");
  background-position: center;
  opacity: 0;
  transition: opacity .2s;
}

.checkbox:checked::before,
.radio:checked::before {
  opacity: 1;
}

.range {
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  display: block;
  height: 30px;
  margin: 0;
  padding-top: var(--space-xs);
  width: 100%;
}

.range::-webkit-slider-runnable-track {
  appearance: none;
  background-color: var(--color-grey-50);
  border: none;
  box-sizing: border-box;
  height: 2px;
  width: 100%;
}

.range::-moz-range-track {
  appearance: none;
  background-color: var(--color-grey-50);
  border: none;
  box-sizing: border-box;
  height: 2px;
  width: 100%;
}

.range::-ms-track {
  appearance: none;
  background-color: var(--color-grey-50);
  box-sizing: border-box;
  height: 2px;
  width: 100%;
}

.range::-moz-range-progress {
  appearance: none;
  background-color: var(--color-black);
  height: 2px;
}

.range::-ms-fill-lower {
  appearance: none;
  background-color: var(--color-black);
  height: 2px;
}

.range::-ms-fill-upper { /* IE11 */
  appearance: none;
  background-color: var(--color-grey-50);
  height: 2px;
}

.range::-webkit-slider-thumb {
  appearance: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-50);
  border-radius: 50%;
  box-sizing: border-box;
  height: 26px;
  margin-top: -12px;
  width: 26px;
}

.range::-moz-range-thumb {
  appearance: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-50);
  border-radius: 50%;
  box-sizing: border-box;
  height: 26px;
  width: 26px;
}

.range::-ms-thumb {
  appearance: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-50);
  border-radius: 50%;
  box-sizing: border-box;
  height: 26px;
  margin-top: 0;
  width: 26px;
}

.range::-ms-tooltip {
  display: none;
}

.range::-ms-ticks-after {
  display: none;
}

.range::-ms-ticks-before {
  display: none;
}

.range:focus {
  box-shadow: none;
  outline: none;
}

.range::-webkit-slider-thumb:hover {
  border: 1px solid var(--color-black);
}

.range::-moz-range-thumb:hover {
  border: 1px solid var(--color-black);
}

.range::-ms-thumb:hover {
  border: 1px solid var(--color-black);
}

.select:focus,
.textInput:focus,
.radio:focus,
.checkbox:focus,
.range:focus {
  outline: 1px dotted var(--color-grey-40);
  outline-offset: 1px;
}

.textInput[disabled],
.checkbox[disabled],
.radio[disabled],
.range[disabled],
.select[disabled] {
  cursor: initial;
}
