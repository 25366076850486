.container {
  display: grid;
  grid-template-rows: 1fr auto;
  width: 900px;
  max-width: 100%;
  height: 80vh;
  min-height: 300px;
  padding: 0 var(--space-xxl);
  margin: var(--icon-size-large) 0 var(--space-xxl);
}

.conflictItemsWrapper {
  position: relative;
  overflow-y: auto;
}

.subtitle {
  font-weight: bold;
}

.sum {
  font-weight: bold;
  text-align: right;
}

.notResolvableDisclaimer {
  font-weight: bold;
  color: var(--color-red);
}

.buttonList {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding-left: 0;
}

.buttonListItem + .buttonListItem {
  margin-left: 1em;
}

.button {
  background-color: var(--primary-button-bg-color);
  color: var(--primary-button-color);
  border-width: 0;
  padding: var(--space-s) var(--space-m);
}
