.row {
  padding: 10px 0;
  gap: var(--space-m);
  border-bottom: 1px solid var(--color-grey-40);
}

.row:last-child {
  border-bottom: none;
}

.nameWrapper {
  font-weight: normal;
  color: var(--color-grey-90);
}

.name {
  min-width: 50%;
  color: var(--color-grey-90);
}

.displayId {
  margin-left: var(--space-xs);
  font-size: .75em;
  color: var(--color-grey-50);
}

.textAndImage {
  display: flex;
}

.price {
  font-weight: bold;
  color: var(--color-grey-90);
}

.media {
  overflow: hidden;
  width: 100%;
}

.media img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.addButton {
  background: var(--primary-button-bg-color);
  color: var(--primary-button-color);
  border: none;
  padding: var(--space-xs) var(--space-l);
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color .3s ease;
  min-width: 80px;
  text-align: center;
}

.addButton:disabled {
  background-color: var(--color-grey-50);
  cursor: not-allowed;
}

.footerWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.priceWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
