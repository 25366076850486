.container {
  margin-bottom: var(--space-l);
}

.icon {
  display: block;
  float: left;
  margin-right: var(--space-s);
  margin-top: -3px;
  height: var(--icon-size-small);
  width: var(--icon-size-small);
}

.button {
  text-decoration: underline;
  background-color: transparent;
  border: none;
}
