.button {
  display: inline-flex;
  align-items: center;
  padding: 0;
  border-width: 0;
  background-color: transparent;
  text-align: left;
}

.button:hover {
  color: var(--color-black);
}

.button[disabled] {
  color: var(--color-grey-20);
}

.iconWrapper {
  margin-right: var(--space-s);
}
