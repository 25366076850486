.container {
  padding: 0;
  min-width: 100%;
}

.modelSelectorWrapper {
  padding: 15px 0;
  display: block;
}

.buttonsWrapper {
  clear: both;
  margin: 0;
  display: flex;
  flex-direction: row;
  padding: 0 15px;
}

.compareButton {
  margin: 0 15px 0 0;
  padding: 10px 15px !important;
}

.compareButton[disabled] {
  background-color: var(--color-grey-40);
}

.distinctWrapper {
  display: inline-flex;
  padding: 2px 0 0;
}

.distinctWrapper input {
  margin: 5px 10px 0 0;
  float: left;
}

.distinctWrapper span {
  line-height: 34px;
}
