.button {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border-width: 0;
  padding: 0;
}

.icon {
  margin-left: var(--space-s);
}
