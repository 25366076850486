.container {
  position: fixed;
  flex-shrink: 0;
  top: 0;
  height: 100%;
  width: var(--sidebar-open-width);
  padding-bottom: var(--space-xxl);
  transition: width var(--transition-ease-in-out) var(--time-l);
  overflow: hidden;
  z-index: 2;
}

.container::before {
  background-color: var(--sidebar-bg-color);
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 999em;
  height: 100%;
  z-index: -1;
}

.sidebarClosed {
  width: var(--sidebar-closed-width);
  max-width: unset;
  min-width: unset;
}

.toggleButton {
  color: var(--color-black);
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  background-color: transparent;
  border-width: 0;
}

@media screen and (min-width: 1281px) {
  .container {
    position: sticky;
    height: 100vh;
    min-width: var(--sidebar-open-width);
    width: 300px;
    overflow: visible;
    padding-bottom: 0;
  }

  .toggleButton {
    display: none;
  }
}
