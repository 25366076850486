.subTitle {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xl);
}

.envkvLabelWrapper {
  margin: 15px 0;
}

.envkvLabelImage {
  width: 100%;
}
