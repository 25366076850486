.dropdownSelect {
  margin-left: 50px;
  width: 200px;
  position: relative;
}

.date {
  flex: 1;
}

.vehiclesNumber {
  flex: 1;
  text-align: left;
  font-weight: bold;
  font-size: .8rem;
  margin: auto;
}

.dropdownContainer {
  position: sticky;
  top: 0;
  width: 100%;
  background: var(--color-white);
  padding-bottom: var(--space-s);
  padding-top: var(--space-l);
}
