.container + .container {
  margin-top: var(--space-m);
}

.selectedItemContainer,
.alternativeItemContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1.5em;
  margin-bottom: 2px;
  padding: var(--space-s);
}

.toggleButton {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--space-xs);
  border-width: 0;
  text-align: left;
  font-weight: bold;
}

.selectedItemContainer,
.alternativeItemContainer,
.toggleButton {
  background-color: var(--color-grey-10);
}

.icon {
  margin-right: .5em;
}
