.nameCol,
.priceCol,
.codeCol {
  padding: var(--space-s) 0;
}

.nameCol {
  width: 100%;
  padding-right: var(--space-s);
}

.nameColInner {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: var(--space-m) var(--space-s);
}

.discountNameColInner {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: var(--space-m) var(--space-s);
}

.infoButtonWrapper {
  grid-column-start: 2;
}

@media screen and (min-width: 769px) {
  .nameColInner {
    grid-template-columns: auto 1fr 100px;
    grid-template-rows: initial;
  }

  .discountNameColInner {
    grid-template-columns: auto 1fr 120px;
    grid-template-rows: initial;
  }

  .infoButtonWrapper {
    grid-column-start: initial;
  }
}

.label[for] {
  cursor: pointer;
}

.labelIsConflictual {
  color: var(--color-grey-40);
}

.labelIsReplaced b {
  text-decoration: line-through;
}

.checkbox[disabled] {
  border-width: 0;
}

.displayId {
  margin-left: var(--space-xs);
  color: var(--color-grey-50);
  font-weight: 200;
  font-size: .75em;
  text-align: center;
}

.priceCol {
  padding-left: var(--space-m);
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
}

.priceContainer {
  margin-bottom: var(--space-s);
}

.lockIcon {
  margin-right: var(--space-xs);
}

.optionBtn {
  padding-right: var(--button-horizontal-padding);
  padding-left: var(--button-horizontal-padding);
  border-width: 0 !important;
  background-color: transparent !important;
}

.icon {
  margin-right: var(--space-xs);
  width: var(--icon-size);
}

.optionsWrapper {
  display: inline-flex;
}

.amountWrapper {
  margin: 0 0 5px;
}

.itemPriceWrapper {
  margin: 0 0 5px;
}

.itemPrice {
  margin-left: 5px;
}

.detailedLabel {
  display: flex;
}

.detailedList {
  margin: 0;
}

.detailedName {
  display: flex;
  align-items: flex-start;
}

.detailedName dt {
  width: 150px;
  flex-shrink: 0;
  margin-right: 5px;
}

.detailedName dd {
  margin: 0;
  padding-left: 10px;
  flex-grow: 1;
}
