.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  grid-gap: 0 var(--space-m);
  grid-auto-flow: dense;
}

.toggleButtonWrapper {
  margin-bottom: var(--space-l);
}

.toggleButton {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border-width: 0;
  padding: 0;
}

.icon {
  margin-left: var(--space-s);
}

.imgPlaceholder {
  display: block;
  margin-bottom: var(--space-l);
  border-bottom: 3px solid transparent;
}
