.container {
  margin-top: var(--space-xxl);
}

.textContainer {
  overflow: hidden;
  line-height: 1.5;
  white-space: normal;
  word-break: break-word;
  margin: 0;
}

.expandButton {
  background: none;
  border: none;
  color: var(--color-black);
  font-weight: bold;
  cursor: pointer;
  padding: 0;
  font-size: 1em;
  margin-top: .5em;
}
