.modelSelectorBlock {
  width: 33.33%;
  padding: 0 15px 0 55px;
  position: relative;
  border-right: 1px solid var(--color-grey-40);
  margin: 0 0 20px 0;
  box-sizing: border-box;
  float: left;
}

.modelSelectorBlockLast {
  border-right: 0 solid var(--color-grey-40);
}

.modelSelectorNumber {
  position: absolute;
  top: 5px;
  left: 15px;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 1.25em;
  line-height: 30px;
  background-color: var(--color-grey-10);
}

.useConfigurationButton {
  position: absolute;
  bottom: 5px;
  left: 15px;
  width: 30px;
  padding: 5px 0 0;
  height: 30px;
  text-align: center;
  background-color: var(--color-grey-10);
  cursor: pointer;
}

.useConfigurationButton svg {
  width: 20px;
  height: 20px;
}

.modelSelectorCarline {
  margin: 0 0 10px;
}

.select {
  width: 100%;
  padding: 0 var(--space-s);
}
