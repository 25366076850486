.headingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xl);
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1em;
}

.nameCol,
.valueCol {
  padding: var(--space-s);
}

.nameCol {
  width: 38%;
}

.nameContainer {
  display: flex;
  align-items: center;
}

.infoButtonWrapper {
  margin-left: var(--space-s);
}

.valueCol {
  width: 62%;
}

.infoBlock {
  margin-top: var(--space-s);
}
