.nameCol,
.attributeCol,
.priceCol {
  padding: var(--space-m) var(--space-s);
}

.nameCol {
  padding-left: 0;
  width: 60%;
}

.nameColInner {
  display: grid;
  grid-column-gap: var(--space-m);
  grid-template-columns: auto 1fr;
}

.label {
  cursor: pointer;
}

.subtext {
  color: var(--color-grey-40);
  font-size: 12px;
  line-height: 1.5;
  margin-top: var(--space-s);
  margin-bottom: 0;
  white-space: pre-line;
}

.priceCol {
  padding-right: 0;
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
}

.priceContainer {
  margin-bottom: var(--space-s);
}

.discountContainer {
  margin-top: 10px;
  font-weight: 400;
}

.discountContainerIcon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: top;
}

.discountContainerText {
  display: inline-block;
  line-height: 24px;
  margin-right: 10px;
  vertical-align: top;
}
