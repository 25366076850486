.container {
  width: 900px;
  max-width: 100%;
  height: 80vh;
  min-height: 300px;
  padding: 0 var(--space-xxl);
  margin: var(--icon-size-large) 0 var(--space-xxl);
  position: relative;
  overflow-y: auto;
  text-align: left;
}
