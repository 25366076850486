.col {
  padding-top: var(--space-s);
  padding-bottom: var(--space-s);
  word-break: break-word;
}

.valueCol {
  text-align: right;
  padding-left: var(--space-xs);
}

.figure {
  margin-bottom: 0;
}
