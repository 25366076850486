.container {
  padding-right: var(--space-column);
  padding-bottom: var(--space-xxl);
  padding-left: var(--space-column);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xl);
}

.title {
  margin-top: 0;
  margin-bottom: 0;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: middle;
  fill: #fff;
}

.modalButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modalButtons button {
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color .3s;
}

.modalContent {
  text-align: left;
}

.textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #d3d3d3;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
