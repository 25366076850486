.container {
  padding-left: var(--space-column);
  padding-right: var(--space-column);
}

.check {
  color: var(--color-green);
}

.cancel {
  color: var(--color-red);
}

.check,
.cancel {
  display: block;
}

.table {
  width: auto;
}

.td {
  padding: var(--space-s);
  vertical-align: middle;
}
