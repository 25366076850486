.wrapper,
.wrapperForced {
  --size: 25px;

  display: inline-block;
  width: var(--size);
  height: var(--size);
  border: 1px solid currentColor;
  border-radius: 100%;
}

.wrapperForced {
  border-color: var(--color-red);
}
