@font-face {
  font-family: MAN-regular;
  font-display: block;
  src: url("../../../assets/fonts/man-regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: MAN-bold;
  font-display: block;
  src: url("../../../assets/fonts/man-bold.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: MAN-light;
  font-display: block;
  src: url("../../../assets/fonts/man-light.woff2") format("woff2");
  font-weight: 400;
}

:root {
  --man-type-regular: "MAN-regular", sans-serif;
  --man-type-bold: "MAN-bold", sans-serif;
  --man-type-light: "MAN-light", sans-serif;
}
