.container {
  padding: var(--space-xl);
}

.title {
  margin-top: .5em;
  text-align: center;
}

.input {
  width: 100%;
  margin-bottom: var(--space-l);
  text-align: center;
}

.button {
  width: 100%;
  padding: var(--space-m);
  background-color: var(--color-black);
  color: var(--color-white);
  border-width: 0;
}
