.modalContent {
  height: 90vh;
  width: 90vw;
  max-width: 900px;
}

.closingButtonWrapper {
  position: sticky;
  top: 0;
}

.container {
  padding: var(--icon-size-large) var(--space-xxl);
  text-align: left;
}

.title {
  font-size: 1.6rem;
  line-height: 1.5;
}

.equipmentKey {
  color: var(--color-grey-60);
  font-size: 50%;
  padding-left: var(--space-s);
}

.textItem {
  margin-bottom: var(--space-m);
}

.texts ul {
  padding: 0 1rem 0;
}

.texts p {
  padding: 0 0 var(--space-m);
}

.texts .ak_text {
  padding-bottom: 10px;
}

.texts .ak_remarks {
  padding-top: 20px;
}

.texts .benefits br {
  display: none;
}

.forced {
  border: 1px solid var(--color-red);
  padding: var(--space-m);
}
