.container {
  margin: var(--icon-size-large) var(--space-xl) var(--space-xl);
}

.headline {
  white-space: nowrap;
}

.spinnerContainer {
  background-color: var(--color-grey-10);
  padding: var(--space-s);
  text-align: center;
  height: calc(2em + 2 * var(--space-s));
}

.mainContent {
  text-align: center;
  font-size: 2em;
  line-height: 1;
  margin: 0;
}

.linkContent {
  text-align: center;
  font-size: 2em;
  line-height: 1;
  margin: 0;
}

.urlLink {
  display: inline-block;
  vertical-align: top;
  font-size: 17px;
  margin-top: 11px;
}

.urlButton {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}

.urlCopyButton {
  cursor: pointer;
}

.copied {
  background-color: var(--color-green) !important;
  border-color: transparent;
}

.copiedSvg {
  stroke: #fff;
  display: inline-block;
  vertical-align: top;
  margin-top: 3px;
}

.info {
  margin-top: 20px;
  font-size: 14px;
}

.dealerWrapper {
  margin: 0 0 20px;
}

.dealerInput {
  font-size: 16px;
  text-align: center;
}

.dealerInputError {
  border-color: var(--color-red);
}

.dealerInputErrorMessage {
  font-size: 14px;
  color: var(--color-red);
  margin-top: var(--space-s);
}

.button {
  background-color: var(--color-grey-10);
  display: block;
  padding: var(--space-s);
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin: 10px 0 0;
}

.button:hover {
  background-color: var(--color-grey-15);
}
