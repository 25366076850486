.container {
  overflow: hidden;
  transition: height .3s ease;
  background: var(--color-white);
  border-bottom: 1px solid black;
  width: 100%;
  margin-bottom: 25px;
}

.expanded {
  position: absolute;
  height: auto;
  overflow-y: auto;
  display: block !important;
  background: var(--color-white);
  z-index: 1;
  border-bottom: 1px solid var(--color-black);
}

.header {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 var(--space-m);
}

.headerTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: .5em;
  display: inline-block;
  min-width: 2em;
  text-align: center;
}

.recommendationsList {
  padding: 0 15px;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
  display: none;
  z-index: 1;
}

.recommendationsList > * {
  width: 100%;
}
