@font-face {
  font-family: SkodaNext;
  font-weight: 900;
  font-display: swap;
  font-style: italic;
  src: url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-BlackItalic.52842cbb347ce0cf180ed384e4574ce3.ttf");
  src:
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-BlackItalic.54778c650e467f12ca4cc49bc9f89021.eot?#iefix") format("embedded-opentype"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-BlackItalic.5848dc93a5f2b06b68b9dcffa38ee2f8.woff2") format("woff2"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-BlackItalic.1629b142f038c1beaf6efefc8af0853a.woff") format("woff"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-BlackItalic.52842cbb347ce0cf180ed384e4574ce3.ttf") format("truetype");
}

@font-face {
  font-family: SkodaNext;
  font-weight: 900;
  font-display: swap;
  font-style: normal;
  src: url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Black.973bdb12aecdd279754004d6220f9c35.ttf");
  src:
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Black.5ffd20e3801c65f111da891d35d698ca.eot?#iefix") format("embedded-opentype"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Black.d571105ffd4442f57e3d801894e5061f.woff2") format("woff2"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Black.93675509e43056da3060c930f780685b.woff") format("woff"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Black.973bdb12aecdd279754004d6220f9c35.ttf") format("truetype");
}

@font-face {
  font-family: SkodaNext;
  font-weight: 700;
  font-display: swap;
  font-style: italic;
  src: url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-BoldItalic.5dde2f1f71df81993b1388439857417e.ttf");
  src:
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-BoldItalic.4d1007bb44d7e3509c3cb8b57d9c3b5f.eot?#iefix") format("embedded-opentype"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-BoldItalic.312a27b1f0258d817fb4dbdd72e8fdaf.woff2") format("woff2"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-BoldItalic.a4345f25d6144bd2196f1d43c30c3cb6.woff") format("woff"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-BoldItalic.5dde2f1f71df81993b1388439857417e.ttf") format("truetype");
}

@font-face {
  font-family: SkodaNext;
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  src: url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Bold.1bf8291a296c00253a1b7c870dcc6703.ttf");
  src:
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Bold.c2517e26600a38d16af9179a777f2142.eot?#iefix") format("embedded-opentype"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Bold.9224be78d94db6f83ab9072975fd0051.woff2") format("woff2"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Bold.c2ca028aef83f6b77df85afe0cbded62.woff") format("woff"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Bold.1bf8291a296c00253a1b7c870dcc6703.ttf") format("truetype");
}

@font-face {
  font-family: SkodaNext;
  font-weight: 300;
  font-display: swap;
  font-style: italic;
  src: url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-RegularItalic.ba2dc728c5354dceddefb15bb9d12aa5.ttf");
  src:
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-RegularItalic.a63a682fe0e1620114a8e6088670df1f.eot?#iefix") format("embedded-opentype"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-RegularItalic.00b9c6c7895280a098b81e006a5e4cf6.woff2") format("woff2"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-RegularItalic.43bd0ff4eb44268bdf2cb7c404876eb2.woff") format("woff"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-RegularItalic.ba2dc728c5354dceddefb15bb9d12aa5.ttf") format("truetype");
}

@font-face {
  font-family: SkodaNext;
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  src: url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Regular.a4ef0274228684ec2d8e1c23b61f3575.ttf");
  src:
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Regular.9b7af45a9acd38598770ab8f237831da.eot?#iefix") format("embedded-opentype"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Regular.896bab255b5e7a7c32a6ad50105f1e80.woff2") format("woff2"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Regular.f87b11e382f983a35a1506c70af860d9.woff") format("woff"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Regular.a4ef0274228684ec2d8e1c23b61f3575.ttf") format("truetype");
}

@font-face {
  font-family: SkodaNext;
  font-weight: 100;
  font-display: swap;
  font-style: italic;
  src: url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-LightItalic.0c760fa94d09c617c4b71cd2b3c5760a.ttf");
  src:
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-LightItalic.f49fbb85be2944cc7fec7a4b8fa59373.eot?#iefix") format("embedded-opentype"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-LightItalic.c5e8eb46c700bc4e441dd3679b95192c.woff2") format("woff2"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-LightItalic.14eb55549e80e7055d3ab43cc7b9890e.woff") format("woff"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-LightItalic.0c760fa94d09c617c4b71cd2b3c5760a.ttf") format("truetype");
}

@font-face {
  font-family: SkodaNext;
  font-weight: 100;
  font-display: swap;
  font-style: normal;
  src: url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Light.9be74ebc7639857e8b5483230db78b08.ttf");
  src:
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Light.c02c3e243dddd158d2c8f871160e38f3.eot?#iefix") format("embedded-opentype"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Light.2070d1d3ea06e9f5acf4e56b8cd8c38a.woff2") format("woff2"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Light.ef226c7115a629abbf1b30e7babfac0e.woff") format("woff"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Light.9be74ebc7639857e8b5483230db78b08.ttf") format("truetype");
}

@font-face {
  font-family: SkodaNext;
  font-weight: 90;
  font-display: swap;
  font-style: italic;
  src: url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-ThinItalic.7f050ae3c657c3fa1fa8e7eebfae3443.ttf");
  src:
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-ThinItalic.7adac9ea045218676aebc842002a4aec.eot?#iefix") format("embedded-opentype"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-ThinItalic.10d5bf962b4055f3a5fd2c0e32f540ca.woff2") format("woff2"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-ThinItalic.9f6c31727775ca19e56170450c7fb889.woff") format("woff"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-ThinItalic.7f050ae3c657c3fa1fa8e7eebfae3443.ttf") format("truetype");
}

@font-face {
  font-family: SkodaNext;
  font-weight: 90;
  font-display: swap;
  font-style: normal;
  src: url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Thin.37df30034bb31e39be7a6ac5f7fd1e7c.ttf");
  src:
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Thin.fcdeded3381a8d7ea1365870016114e0.eot?#iefix") format("embedded-opentype"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Thin.828e2ed5db94ed9bb11b23b3601d21fa.woff2") format("woff2"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Thin.06e8cae10278816d33795d74cfda6ef9.woff") format("woff"),
    url("https://cpv3prodbluecdnep.azureedge.net/resources5/Libs_c2911565-9b23-426f-b6df-3f86a0935d42/Assets/font/SKODANext-Thin.37df30034bb31e39be7a6ac5f7fd1e7c.ttf") format("truetype");
}

:root {
  --skoda-next-type-text: "SkodaNext", sans-serif;
}
