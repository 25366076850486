.container {
  padding: var(--space-xl);
  max-width: 700px;
  height: auto;
  visibility: visible;
  z-index: 3000;
  display: block;
  top: 523px;
  left: 680px;
}

.title {
  font-weight: bold;
  margin-top: .5em;
  border-bottom: 1px solid #bbbec3 !important;
  text-align: center;
}

.button {
  width: 70%;
  padding: var(--space-m);
  background-color: var(--color-black);
  color: var(--color-white);
  border-width: 0;
  margin-top: .9em;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textInput {
  border-bottom: 1px dotted var(--input-border-color);
}

.explanation {
  color: #41464c;
  display: block;
  font-size: .7em;
  margin-top: -20px;
}

.explanationHeading {
  font-weight: bold;
}

.textIntro {
  margin: 10px 0 5px;
}

.input {
  background: #e1e1e1;
  line-height: 15px;
  margin: 10px 0;
  min-width: 350px;
  padding: 2px 1px;
  width: 100%;
}

.fastConfigInputsTable table td {
  border-width: 0 !important;
}

.explanationContainer {
  margin-top: -8px;
}

.submitIcon {
  width: 48px;
  height: 48px;
}

.submitButton {
  display: block;
  align-items: center;
  margin-top: 10px;
  background-color: var(--color-black);
  border-width: 0;
  padding: 10px;
  color: white;
}

.submitContainer {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}

.noBorder {
  border-width: 0 !important;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
}
