.title {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-m);
}

.fallbackLink {
  font-weight: bold;
}

.nameCol,
.priceCol,
.optionCol {
  padding-top: var(--space-s);
  padding-bottom: var(--space-s);
}

.nameCol {
  width: 100%;
}

.nameContainer {
  margin-bottom: var(--space-s);
}

.replacedBy {
  color: var(--color-grey-50);
  font-size: .875em;
  margin-top: 3px;
}

@media screen and (min-width: 769px) {
  .nameColInner {
    display: flex;
    justify-content: space-between;
  }

  .nameContainer {
    margin-bottom: 0;
  }
}

.evaKey {
  margin-left: var(--space-s);
  color: var(--color-grey-50);
  font-weight: 200;
  font-size: .75em;
}

.priceCol {
  text-align: right;
  padding-left: var(--space-s);
  white-space: nowrap;
}

.optionCol {
  --col-horizontal-padding: var(--space-m);
  --icon-size: var(--icon-size-small);
  --button-horizontal-padding: var(--space-xs);
  --button-width: calc(var(--icon-size) + var(--button-horizontal-padding) * 2);
  --num-of-buttons: 2;

  min-width: calc(var(--button-width) * var(--num-of-buttons) + var(--col-horizontal-padding) * 2);
  text-align: center;
}

.optionsList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.optionBtn {
  padding-right: var(--button-horizontal-padding);
  padding-left: var(--button-horizontal-padding);
  border-width: 0;
  background-color: transparent;
}

.icon {
  width: var(--icon-size);
}

.quantityWrapper {
  margin: 0 0 5px;
}

.itemPriceWrapper {
  margin: 0 0 5px;
}

.quantityAmount {
  margin-left: 5px;
}

.replacedByName {
  text-decoration-line: line-through;
}

.replacedByEvaKey {
  text-decoration-line: line-through;
}

.detailedLabel {
  display: flex;
}

.detailedList {
  margin: 0;
  display: inline-block;
  vertical-align: top;
}

.detailedName {
  display: flex;
  align-items: flex-start;
}

.detailedName dt {
  width: 150px;
  flex-shrink: 0;
  margin-right: 5px;
}

.detailedName dd {
  margin: 0;
  padding-left: 10px;
  flex-grow: 1;
}
