.link {
  text-decoration: underline;
}

.disclaimer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: var(--space-s);
  background-color: var(--color-yellow);
  padding: var(--space-s);
  margin-top: var(--space-l);
  margin-bottom: var(--space-l);
}
