.modalContainer {
  padding: 2rem;
  margin: auto;
  max-width: 900px;
}

.modalTitle {
  font-weight: var(--font-weight-bold);
  margin-bottom: 3rem;
  text-align: center;
  border-bottom: 1px solid #bbbec3 !important;
}

.modalMessage {
  display: flex;
  align-items: center;
  gap: .5rem;
  color: var(--color-red);
  background-color: var(--color-grey-05);
  padding: 1rem;
  border-radius: 4px;
  border-left: 4px solid var(--color-red);
  margin-bottom: 1.5rem;
}

.icon {
  margin-right: 10px;
}

.section {
  margin-bottom: 1.5rem;
}

.sectionTitle {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: .5rem;
}

.invocation {
  background-color: var(--color-grey-05);
  padding: 1rem;
  font-size: .9rem;
  word-break: break-word;
}

.matchingModelsSection {
  display: flex;
  align-items: center;
  gap: .5rem;
  font-weight: var(--font-weight-bold);
  color: var(--color-black);
}

.options {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  overflow-y: auto;
  max-height: 250px;
  padding: 10px;
}

.option {
  padding: 1rem;
  word-break: break-word;
  color: var(--color-black);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1), 0 -1px 2px rgba(0, 0, 0, .1), 1px 0 2px rgba(0, 0, 0, .1), -1px 0 2px rgba(0, 0, 0, .1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  text-align: left;
  width: 100%;
  background-color: transparent;
  border: none;
}

.radio {
  outline: none;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color .3s ease, border-color .3s ease;
}

.radio:hover {
  background-color: var(--color-green);
}

.radio:checked {
  background-color: gray;
  border-color: gray;
}

.option:hover {
  background-color: var(--color-grey-15);
}

.option:hover .radio {
  background-color: var(--color-green);
}
