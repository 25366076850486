.container {
  background-color: var(--color-grey-10);
  display: grid;
  grid-template-columns: 1fr .4fr .2fr .4fr;
  grid-column-gap: 1em;
  margin-bottom: 2px;
  padding: var(--space-s);
}

.containerWithCheckbox {
  grid-template-columns: auto 1fr .4fr .2fr .4fr;
}

.label {
  font-weight: bold;
}

.price {
  font-weight: bold;
}

.info {
  text-align: right;
  margin-top: 5px;
}

.evaKey {
  color: var(--color-grey-50);
  font-weight: 220;
  font-size: .85em;
  display: flex;
  justify-items: center;
  justify-content: center;
}

.labelAndName {
  display: flex;
  flex-direction: column;
}

.priceAndInfo {
  justify-items: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 50px;
}

.pricePlaceholder {
  height: 20px;
  width: 80px;
  margin-bottom: 6px;
  visibility: hidden;
}

.infoPlaceholder {
  height: 24px;
  width: 24px;
  visibility: hidden;
}

.imagePlaceholder {
  width: 100px;
  height: 56px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
