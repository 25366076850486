.button,
.buttonPlaceholder {
  padding: 0 0 45px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-40);
  transition: all .2s;
  position: relative;
  margin: 20px 0 0;
}

.buttonOpened,
.button:hover {
  border-bottom-color: var(--color-grey-60);
}

.img,
.imgPlaceholder {
  display: block;
  margin: auto;
}

.titlePlaceholder,
.title {
  display: inline-block;
  width: 100%;
  border-top: 1px solid var(--color-grey-40);
  padding: var(--space-s);
  text-align: left;
  font-family: var(--secondary-font-family);
  transition: background-color .2s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.titlePlaceholder {
  color: var(--color-grey-20);
  border-top: 1px solid var(--color-grey-20);
}

.buttonOpened .titlePlaceholder,
.button:hover .titlePlaceholder,
.buttonOpened .title,
.button:hover .title {
  background: var(--color-grey-10);
}
