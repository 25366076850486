.title {
  display: block;
  font-size: .875em;
  color: var(--label-font-color);
}

.wrapper-horizontal {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-gap: 20px;
  margin-bottom: var(--space-xl);
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.inputList {
  list-style: none;
  padding-left: 0;
  margin: var(--space-s) 0;
}

.inputListItem + .inputListItem {
  margin-top: var(--space-s);
}

.inputLabelWrapper {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.inputLabel {
  margin-left: var(--space-s);
}

.resetFilters {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border-width: 0;
  padding: 0;
  margin: 0;
  font-size: 14px;
  gap: 5px;
}

.disabledResetFilter {
  color: var(--color-grey-30);
}
