:global(.miwi-button) {
  display: none;
}

.miwiButtonWrapperLoading {
  display: none;
}

:global(.miwi-button) + .miwiButtonWrapper .trafficLight {
  background-color: var(--color-grey-20);
}

:global(.miwi-button-authenticated) + .miwiButtonWrapper .trafficLight {
  background-color: var(--color-yellow);
}

:global(.miwi-button-connected) + .miwiButtonWrapper .trafficLight {
  background-color: var(--color-green);
}
