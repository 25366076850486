@font-face {
  font-family: AudiTypeWide;
  font-style: normal;
  font-weight: 200;
  src: url("https://assets.audi.com/audi-fonts/1/AudiType-WideLight.woff2") format("woff2"), url("https://assets.audi.com/audi-fonts/1/AudiType-WideLight.woff") format("woff");
}

@font-face {
  font-family: AudiTypeWide;
  font-style: normal;
  font-weight: 400;
  src: url("https://assets.audi.com/audi-fonts/1/AudiType-WideNormal.woff2") format("woff2"), url("https://assets.audi.com/audi-fonts/1/AudiType-WideNormal.woff") format("woff");
}

@font-face {
  font-family: AudiTypeWide;
  font-style: normal;
  font-weight: 700;
  src: url("https://assets.audi.com/audi-fonts/1/AudiType-WideBold.woff2") format("woff2"), url("https://assets.audi.com/audi-fonts/1/AudiType-WideBold.woff") format("woff");
}

@font-face {
  font-family: AudiTypeExtended;
  font-style: normal;
  font-weight: 400;
  src: url("https://assets.audi.com/audi-fonts/1/AudiType-ExtendedNormal.woff2") format("woff2"), url("https://assets.audi.com/audi-fonts/1/AudiType-ExtendedNormal.woff") format("woff");
}

@font-face {
  font-family: AudiTypeExtended;
  font-style: normal;
  font-weight: 700;
  src: url("https://assets.audi.com/audi-fonts/1/AudiType-ExtendedBold.woff2") format("woff2"), url("https://assets.audi.com/audi-fonts/1/AudiType-ExtendedBold.woff") format("woff");
}

:root {
  --audi-type-wide: AudiTypeWide, sans-serif;
  --audi-type-extended: AudiTypeExtended, sans-serif;
}
