.container {
  display: flex;
  justify-content: flex-end;
  font-size: .75rem;
}

.button {
  margin-left: .5rem;
  padding: 0;
  background-color: transparent;
  border: 0;
  text-decoration: underline;
}
