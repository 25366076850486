.numberSelect {
  display: inline-block;
}

.labelText {
  display: inline-block;
  margin: 0 10px 0 0;
}

.select {
  text-align: center;
}
