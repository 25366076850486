.container {
  position: fixed;
  top: 28px;
  left: -56px;
  width: 224px;
  background-color: var(--color-red);
  color: var(--color-white);
  text-align: center;
  transform: rotate(-45deg);
  cursor: default;
  z-index: 3;
}

.container::before,
.container::after {
  box-shadow: 1px 8px 6px rgba(0, 0, 0, .3);
  content: "";
  left: 2px;
  max-width: 300px;
  position: absolute;
  bottom: 4px;
  transform: rotate(4deg);
  width: 50%;
  z-index: -1;
  height: 100%;
}

.container::after {
  left: auto;
  right: 2px;
  transform: rotate(-4deg);
}

.container .text {
  background-color: var(--color-red);
  padding: var(--space-s);
  display: block;
}
