.containerForced {
  border: 1px solid var(--color-red);
}

.imgContainer {
  position: relative;
}

.img {
  display: block;
  margin: 0 auto;
}

.sliderButtonList {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.sliderButton {
  padding: 0;
  background-color: var(--color-white);
  border-width: 0;
}

.thumbnailList {
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  padding: var(--space-m) var(--space-s);
  list-style: none;
}

.thumbnailListItem {
  width: 100px;
  margin-right: var(--space-s);
}

.thumbnailButton,
.thumbnailButtonActive {
  display: block;
  padding: 0;
  border: 1px solid transparent;
  background-color: transparent;
}

.thumbnailButtonActive {
  border-color: var(--color-black);
}
