.tabLabelsList {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
  padding-left: 0;
  list-style: none;
}

.tabLabelsListItem + .tabLabelsListItem {
  margin-left: var(--space-m);
}

.tabLabelButton,
.tabLabelButtonActive {
  padding: 0;
  background-color: transparent;
  border-width: 0;
}

.tabLabelButtonActive {
  font-weight: bold;
  text-decoration: underline;
}
