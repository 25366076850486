.container {
  padding-right: var(--space-column);
  padding-bottom: var(--space-xxl);
  padding-left: var(--space-column);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xl);
}

.title {
  margin-top: 0;
  margin-bottom: 0;
}

.content {
  margin-top: 30px;
}

.configDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 20px 10px 10px 10px;
}

.details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 10px;
}

.vehicleData {
  display: flex;
  flex-direction: column;
  margin: 0 0 16px;
  gap: 5px;
}

.loadVehicleCodeButton {
  width: 20%;
  padding: var(--space-s) var(--space-l);
  background: var(--primary-button-bg-color);
  color: var(--primary-button-color);
  border: none;
  cursor: pointer;
  transition: background .3s ease;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 220px;
}

.media {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 410px;
}

.placeholder {
  width: 200px;
}

.media img {
  flex: 1;
  width: 100%;
  height: auto;
  object-fit: cover;
  max-width: 200px;
  max-height: 200px;
}

.configDetailsPlaceholder {
  padding: 0 0 0 0;
}

.mediaPlaceholder {
  display: flex;
  width: 25%;
  gap: 10px;
}

.detailsTopPlaceholder {
  margin-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content {
    padding: 10px 10px 0 10px;
  }

  .configDetails {
    flex-direction: column;
    padding: 10px;
  }

  .details {
    padding-right: 0;
  }

  .loadVehicleCodeButton {
    margin-bottom: 10px;
    align-self: flex-start;
  }
}

@media (max-width: 1024px) {
  .content {
    padding: 10px 10px 0 10px;
  }

  .configDetails {
    padding: 10px;
  }

  .details {
    padding-right: 0;
  }

  .loadVehicleCodeButton {
    align-self: flex-start;
    margin-bottom: 10px;
  }
}
