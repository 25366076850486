@font-face {
  font-family: "VW Text";
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/VWTextWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "VW Text";
  font-style: normal;
  font-weight: bold;
  src: url("../../../assets/fonts/VWTextWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: "VW Head";
  font-style: normal;
  font-weight: bold;
  src: url("../../../assets/fonts/VWHeadWeb-Bold.woff") format("woff");
}

:root {
  --vw-type-text: "VW Text", sans-serif;
  --vw-type-head: "VW Head", sans-serif;
}
