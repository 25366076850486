.button {
  padding: 0;
  border: 1px solid var(--color-grey-40);
  margin: 20px 0 0;
  background-color: transparent;
}

.button svg {
  width: 100%;
}

.list {
  border: 0 solid var(--color-grey-40);
}
