.label {
  display: inline-block;
  line-height: 1.5;
  margin-bottom: var(--space-xs);
}

.labelAdd {
  color: var(--color-red);
}

.labelRemove {
  color: var(--color-green);
}
