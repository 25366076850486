@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.container {
  --column-space: var(--space-xxl);

  position: relative;
  grid-column: 1/-1;
  padding-right: var(--column-space);
  padding-left: var(--column-space);
  margin-right: calc(var(--column-space) * -1);
  margin-left: calc(var(--column-space) * -1);
  background-color: var(--color-grey-10);
  animation-name: fade-in;
  animation-duration: var(--time-xl);
  padding-bottom: var(--space-s);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-style: none;
  background-color: transparent;
}

.list {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: var(--space-s) var(--space-m);
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.link {
  display: block;
  margin-bottom: var(--space-s);
  border-bottom: 5px solid transparent;
  transition: border-bottom-color .2s;
}

.link:hover {
  border-bottom-color: var(--color-grey-60);
}

.title {
  display: inline-block;
  width: 100%;
  border-top: 1px solid var(--color-grey-40);
  padding: var(--space-s);
  text-align: left;
  font-family: var(--secondary-font-family);
}

.img,
.placeholder {
  display: block;
}
