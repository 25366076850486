.compareResultWrapper {
  padding: 0;
  margin-top: 20px;
}

.compareResultCategories {
  padding: 0 15px;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
}

.compareResultCategory {
  padding: var(--space-s) var(--space-m);
  background-color: var(--color-grey-20);
  cursor: pointer;
  margin-right: 1px;
}

.compareResultCategory:hover {
  background-color: var(--color-grey-30);
}

.compareResultCategoryActive {
  background-color: var(--color-grey-10);
}

.compareResultCategoryActive:hover {
  background-color: var(--color-grey-10);
}

.compareResultCategoryViews {
  margin: 0;
  padding: 0;
  list-style: none;
}

.compareResultCategoryView {
  padding: 0 15px 15px;
  overflow: auto;
}

.compareResultCategoryView table {
  width: 100%;
  border: 1px solid var(--color-grey-10);
  border-collapse: collapse;
}

.compareResultCategoryView table td {
  border: 1px solid var(--color-grey-10);
  padding: var(--space-s);
  text-align: center;
  font-size: 1em;
  line-height: 1em;
  width: 25%;
}

.tableInfo svg {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
}

.compareResultCategoryView table td svg {
  width: 20px;
  height: 20px;
}

.compareResultCategoryView table .resultRowCategory td {
  background-color: var(--color-grey-10);
}

.compareResultCategoryView table tr td:first-child {
  text-align: left;
}

.compareResultCategoryView table .resultRowDifferent td:first-child {
  color: var(--color-red);
}

.compareResultCategoryViewActive {
  display: block;
}

.tableInfo {
  padding: 10px 15px 15px;
  text-align: center;
}

.tableInfo span {
  margin: 0 10px;
}
